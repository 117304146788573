import { LOCALES } from './locales'

export const messages = {
  [LOCALES.ENGLISH]: {
    login: 'Log in',
    logout: 'Log out',
    signup: 'Sign up',
    my_accaunt: 'My accaunt',
    email_address: 'Email address',
    email: 'Email',
    password: 'Password',
    login_alert: 'Email or password is wrong',
    signup_alert: 'Not all fields are entered correctly',
    surname: 'Surname',
    first_name: 'First name',
    second_name: 'Second name',
    position: 'Position',
    position_info: 'Your position in school',
    teacher: 'Teacher',
    student: 'Student',
    logo: 'Ask lyceum',
    question: 'Your question',
    ask: 'Ask a question',
    reciever: 'Recipient:',
    reciever_info: 'Who do you want to ask the question to',
    answer: 'Answer',
    max_length: 'Symbols left',
    public: 'Public question',
    anonymous: 'Anonymous question',
    ask_alert: 'Enter your question',
    answer_alert: 'Enter your answer',
    close: 'Close',
    wait_answer: 'The teacher has not answered your question yet, expect',
    make_answer: 'Answer the question',
    your_answer: 'Your answer',
    your_question: 'Your question',
    your_question_public: 'Your public question',
    your_question_public_anonymous : 'Your public anonymous question',
    student_question: 'asks',
    student_question_public: 'asks publicly',
    student_anonymous_question: 'Anonymous question from student',
    student_question_public_anonymous : 'Public anonymous question',
    another_student_question_public_anonymous : 'Another user\'s public anonymous question',
    teacher_answer: 'answers',
    change_answer: 'Edit answer',
    save_changes: 'Save changes',
    alert: 'Page is unavailable.',
    is_edited: 'edited',
    success_verify: 'You have confirmed the registration. You can go to the site by following the',
    success_verify_text: 'link',
    success_verify_teacher_admin: 'You have confirmed your registration, and the administrator has also confirmed your registration as a teacher. You can go to the site by following the',
    success_verify_teacher: 'You have confirmed your registration, but the administrator has not yet confirmed your registration as a teacher, expect. You can go to the site by following the',
    answers_info:'There will be questions for you here.',
    questions_info: 'There will be your questions.',
    all_info: 'There will be public questions here.',
    main_page_info: 'To get started, sign up or log in to your account.',
    search: 'Search',
    to_search: 'Search',
    about_welcome: 'Welcome,',
    about_student:'Here you can ask any teacher a question and get an answer to it.',
    about_teacher:'Here you can answer students\' questions and edit your answers.',
    about_all:'Here are the public questions of other users.',
    about_all_start:'To ask your question, sign up or log in to your account.',
    about_edit: 'By clicking on this button you can edit your answer',
    about_hide_on: 'By clicking on this button you can hide your answer to the question',
    about_hide_off: 'By clicking on this button you can show your answer to the question again',
    hide_teacher:'You have hidden the answer to this question, to show it again, click on the corresponding button.',
    hide_student: 'can`t give an answer to this answer.'
  },
  [LOCALES.RUSSIAN]: {
    login: "Вход",
    logout: 'Выход',
    signup: 'Регистрация',
    my_accaunt: 'Мой аккаунт',
    email_address: 'Почтовый адрес',
    email: 'Почта',
    password: 'Пароль',
    login_alert: 'Почта или пароль некорректны',
    signup_alert: 'Не все поля введены корректно',
    surname: 'Фамилия',
    first_name: 'Имя',
    second_name: 'Отчество',
    position: 'Должность',
    position_info: 'Ваша должность в школе',
    teacher: 'Преподаватель',
    student: 'Ученик',
    logo: 'Спрашивай лицей',
    question: 'Ваш вопрос',
    ask: 'Задать вопрос',
    reciever: 'Получатель:',
    reciever_info: 'Кому вы хотите задать вопрос',
    answer: 'Ответ',
    max_length: 'Осталось символов',
    public: 'Публичный вопрос',
    anonymous: 'Анонимный вопрос',
    ask_alert: 'Введите ваш вопрос',
    answer_alert: 'Введите ваш ответ',
    close: 'Закрыть',
    wait_answer: 'Преподователь еще не ответил на ваш вопрос, ожидайте',
    make_answer: 'Ответить на вопрос',
    your_answer: 'Ваш ответ',
    your_question: 'Ваш вопрос',
    your_question_public: 'Ваш публичный вопрос',
    your_question_public_anonymous : 'Ваш публичный анонимный вопрос',
    student_question: 'спрашивает',
    student_question_public: 'спрашивает публично',
    student_question_public_anonymous : 'Публичный анонимный вопрос',
    another_student_question_public_anonymous : 'Публичный анонимный вопрос другого пользователя',
    student_anonymous_question: 'Анонимный вопрос от ученика',
    teacher_answer: 'отвечает',
    change_answer: 'Редактировать ответ',
    save_changes: 'Сохранить изменение',
    alert: 'Страница недоступна.',
    is_edited: 'редактировано',
    success_verify: 'Вы подтвердили регистрацию. Можете перейти на сайт по',
    success_verify_text : 'ссылке',
    success_verify_teacher_admin: 'Вы подтвердили регистрацию, а также администратор подтвердил вашу регистрацию в качестве учителя. Можете перейти на сайт по',
    success_verify_teacher: 'Вы подтвердили регистрацию, но администратор еще не подтвердил вашу регистрацию в качестве учителя, ожидайте. Можете перейти на сайт по',
    answers_info: 'Здесь будут вопросы к вам.',
    questions_info: 'Здесь будут ваши вопросы.',
    all_info: 'Здесь будут публичные вопросы.',
    main_page_info: 'Здесь вы можете задавать вопросы учителям лицея. Они могут быть личными, публичными, а также анонимными. Чтобы приступить к работе, зарегестрируйтесь или войдите в аккаунт.',
    main_page_title_info: 'Добро пожаловать на сайт Лицея №14',
    search: 'Поиск',
    to_search: 'Найти',
    about_welcome: 'Добро пожаловать,',
    about_student:'Здесь вы можете задать вопрос любому учителю и получить на него ответ.',
    about_teacher:'Здесь вы можете отвечать на вопросы учеников и редактировать свои ответы.',
    about_all:'Здесь представлены публичный вопросы других пользователей.',
    about_all_start:'Чтобы задать свой вопрос, зарегистрируйтесь или войдите в аккаунт.',
    about_edit: 'Нажав на эту кнопку вы можете отредактировать свой ответ',
    about_hide_on: 'Нажав на эту кнопку вы можете скрыть свой ответ на вопрос',
    about_hide_off: 'Нажав на эту кнопку вы можете показать снова свой ответ на вопрос',
    hide_teacher:'Вы скрыли ответ на данный вопрос, чтобы показать его снова, нажмите на соответсвующую кнопку.',
    hide_student: 'не может дать ответ на данный ответ.'
  }
}