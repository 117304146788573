import axios from "axios";
import host from '../settings/host'

class PutStatus {
    static async get(access_token, question, toStatus, setPage, renderAnswers, setRenderAnswers, setError) {
        try {
            const s = "Bearer "+access_token
            setPage(1)
            const change_status = await axios.post(`${host}/api/questions/post/${question}/to_${toStatus}/`, {}, {headers: {"Authorization": s}})
            setRenderAnswers(renderAnswers+1)
            return 0;

        } catch (event) {
            setError(event)
            // setValidated(false)
            // event.stopPropagation()
            //
            // setIsAuth(false)
            // localStorage.setItem('error', event.message)
        }
    }
}

export default PutStatus